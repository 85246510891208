<template>
  <div class="game-list-item d-flex flex-md-column">
    <div class="thumbnail-wrapper me-6 me-md-0 mb-md-2" @click="openGameDetails">
      <img v-if="isLoaded" class="thumbnail" :src="thumbnailUrl" alt="" loading="lazy" />
      <v-skeleton-loader v-else color="background" :height="72" type="card"></v-skeleton-loader>
    </div>
    <div class="d-flex w-100">
      <div class="game-info d-flex flex-column" @click="openGameDetails">
        <div class="game-name mb-2">
          {{ isUkrainian && ukrainianName ? ukrainianName : gameName }}
        </div>
        <div class="characteristics d-flex flex-md-column">
          <div class="info-item">
            <v-icon class="me-2" icon="mdi-account-group" />
            <span class="value">{{
              minPlayers === maxPlayers
                ? `${maxPlayers}`
                : `${minPlayers}-${maxPlayers}`
              }}</span>
          </div>
          <div class="info-item">
            <v-icon class="me-2" icon="mdi-clock-outline" />
            <span class="value">{{
              minPlayTime === maxPlayTime
                ? `~ ${maxPlayTime}`
                : `${minPlayTime}-${maxPlayTime}`
              }}</span>
          </div>
          <div class="info-item">
            <v-icon class="me-2" icon="mdi-human-male-boy" />
            <span class="value">{{ minAge }}+</span>
          </div>
        </div>
      </div>

      <div class="actions ms-4" v-if="!hideActions">
        <BottomMenu :menuItems="menuItems" />
        <StarSvg v-if="!hideFavorite" class="mt-4" :class="{ favorite: isFavorite }" @click="changeFavoriteStatus" />
        <div v-else class="action-placeholder"></div>
      </div>
      <div class="action-placeholder" v-else></div>
    </div>
  </div>
</template>

<script>
import ApiUtils from '@/utils/ApiUtils';
import StarSvg from '@/components/icons/StarSvg.vue';
import BottomMenu from '@/components/BottomMenu.vue';
import GameInfoView from '@/classes/GameInfoView';

export default {
  name: 'GameListItem',
  components: {
    StarSvg,
    BottomMenu,
  },
  data() {
    const game = new GameInfoView(this.item);
    return {
      ...game,
      isLoaded: false,
    };
  },
  async mounted() {
    try {
      const cachedGame = this.$store.getters['SearchStore/getGameDetails'](
        this.gameId
      );

      let gameDetails = cachedGame
        ? cachedGame
        : await ApiUtils.getGamesInfo([this.gameId]);

      gameDetails = Array.isArray(gameDetails) ? gameDetails[0] : gameDetails;

      this.$store.commit('SearchStore/setGameDetails', {
        gameId: this.gameId,
        gameDetails,
      });

      this.gameDetails = gameDetails;
      this.gameName = gameDetails.name;
      this.ukrainianName = gameDetails.ukrainianName;
      this.minPlayers = gameDetails.minPlayers;
      this.maxPlayers = gameDetails.maxPlayers;
      this.minPlayTime = gameDetails.minPlayTime;
      this.maxPlayTime = gameDetails.maxPlayTime;
      this.minAge = gameDetails.minAge;
      this.setThumbnailUrl();

      this.isLoaded = true;
    } catch (error) {
      window.console.log(error);
    }
  },
  props: {
    gameId: {
      type: String,
      required: false,
    },
    suppressDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFavorite: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideFavorite: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideActions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isMyProfile() {
      return this.$route.name === 'profile';
    },
    isUkrainian() {
      return this.$i18n.locale === 'ua';
    },
    isAnotherUserProfile() {
      return this.$route.name === 'profileId';
    },
    menuItems() {
      const menuItems = [
        {
          title: this.$t('menuItem.requestGame'),
          icon: 'mdi-account-group',
          color: '',
          handler: () => { },
          isVisible: this.isAnotherUserProfile,
        },
        {
          title: this.$t('menuItem.addToCollection'),
          icon: 'mdi-plus-circle-outline',
          color: '',
          handler: this.changeFavoriteStatus,
          isVisible: !this.isFavorite,
        },
        {
          title: this.$t('menuItem.createEventWithGame'),
          icon: 'mdi-dice-6-outline',
          color: '',
          handler: this.createEventWithGame,
          isVisible: true,
        },
        {
          title: this.$t('menuItem.openBGG'),
          icon: 'mdi-open-in-new',
          color: '',
          handler: this.openBGGRules,
          isVisible: true,
        },
        {
          title: this.$t('menuItem.deleteGame'),
          icon: 'mdi-delete-outline',
          color: '#D63B3B',
          handler: this.changeFavoriteStatus,
          isVisible:
            this.isMyProfile || (this.isFavorite && !this.isAnotherUserProfile),
        },
      ];

      return menuItems.filter((item) => item.isVisible);
    },
  },
  methods: {
    setThumbnailUrl() {
      if (window.innerWidth <= 768) {
        this.thumbnailUrl = this.gameDetails.thumbnail;
      } else {
        this.thumbnailUrl = this.gameDetails.image;
      }
    },
    openGameDetails() {
      if (this.suppressDetails) {
        return;
      }

      this.$router.push({
        name: 'gameInfo',
        params: { id: this.id || this.gameId },
      });
    },
    changeFavoriteStatus() {
      this.$emit('changeFavoriteStatus', this.id || this.gameId);
    },
    openBGGRules() {
      window.open(
        `https://boardgamegeek.com/boardgame/${this.id || this.gameId}`
      );
    },
    createEventWithGame() {
      this.$store.commit('EventStore/setSelectedGame', this.gameDetails);
      this.$router.push({ name: 'createEvent' });
    },
  },
};
</script>

<style lang="scss">
.game-list-item {
  align-items: center;
  justify-content: space-between;

  @media (min-width: 960px) {
    border: 1px rgb(var(--v-theme-primary)) solid;
    padding: 20px;
    border-radius: 22px;
    background-color: rgba(var(--v-theme-abbey), 0.1);
    height: 100%;

    &:hover {
      background-color: rgba(var(--v-theme-abbey-hover), 0.1);
    }
  }

  &.bordered {
    border-bottom: 1px solid rgb(var(--v-theme-mako));

    @media (min-width: 960px) {
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }

  &.selected {
    background-color: rgb(0, 0, 0, 0.3);
  }

  .thumbnail-wrapper {
    width: 72px;
    height: 72px;
    flex-shrink: 0;
    border-radius: 16px;
    overflow: hidden;

    @media (min-width: 960px) {
      width: 100%;
      height: 200px;
    }
  }

  .thumbnail {
    width: 72px;
    height: 72px;
    flex-shrink: 0;
    object-fit: cover;

    @media (min-width: 960px) {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }

  .action-placeholder {
    min-height: 24px;
    min-width: 24px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    color: rgb(var(--v-theme-grey-icon));

    svg {
      width: 24px;
      height: 24px;
      fill: rgb(var(--v-theme-grey-icon));

      &.favorite {
        fill: rgb(var(--v-theme-primary));
      }
    }

    i {
      transform: rotate(90deg);
    }
  }

  .game-info {
    height: 100%;
    flex-grow: 1;
    justify-content: space-between;

    .game-name {
      max-width: 100%;
      font-size: 16px;
      line-height: normal;
    }

    .characteristics {
      font-size: 14px;
      justify-content: space-between;

      i {
        font-size: 24px;
      }

      .info-item {
        display: flex;
        align-items: center;
      }

      .info-item:not(:last-of-type) {
        margin-inline-end: 10px;
      }
    }
  }
}
</style>
