<template>
  <div class="blocked-users-page px-md-16 py-md-7 ml-md-10">
    <div class="users-list-wrapper px-3" v-if="isLoaded">
      <div class="users-list" v-if="users.length">
        <UserListItem
          v-for="user in users"
          :key="user._id"
          :item="user"
          :disable-redirect="true"
          :hide-blocked-label="true"
          @click="onUserClick(user)"
          :hide-controls="true"
        />
      </div>

      <div class="empty-list" v-else>
        <v-icon>mdi-account-off-outline</v-icon>
        <span>{{ $t('label.noBlockedUsers') }}</span>
      </div>
    </div>
    <v-skeleton-loader
      class="px-3"
      v-else
      v-for="i in 10"
      :key="i"
      color="background"
      :height="150"
      type="list-item-avatar-three-line"
    ></v-skeleton-loader>

    <YesNoDialog
      v-if="isBlockDialogVisible"
      v-model="isBlockDialogVisible"
      :dialog-text="$t('dialog.unblockUser', { name: userToUnblock.name })"
      @confirm="onUnblockUser"
      @cancel="onCancelUnblock"
      :is-cancel-red="true"
      :is-confirm-red="false"
    />
  </div>
</template>

<script>
import YesNoDialog from '@/components/views/YesNoDialog.vue';
import UserListItem from '@/components/UserListItem.vue';
export default {
  name: 'BlockedUsers',
  components: {
    YesNoDialog,
    UserListItem,
  },
  data() {
    return {
      users: [],
      isLoaded: false,
      userToUnblock: null,
      isBlockDialogVisible: false,
    };
  },
  beforeUnmount() {
    this.$store.dispatch('UserStore/refreshUser');
  },
  async mounted() {
    try {
      this.isLoaded = false;
      this.users = await this.$store.dispatch(
        'UserStore/fetchUsersList',
        this.blockedUsers
      );
      this.isLoaded = true;
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    currentUserId() {
      return this.$store.getters['UserStore/getUserId'];
    },
    blockedUsers() {
      return this.$store.getters['UserStore/getBlockedUsers'];
    },
  },
  methods: {
    onUserClick(user) {
      this.isBlockDialogVisible = true;
      this.userToUnblock = user;
    },
    async onUnblockUser() {
      try {
        await this.$store.dispatch(
          'UserStore/unblockUser',
          this.userToUnblock._id
        );
        this.users = this.users.filter(
          (user) => user._id !== this.userToUnblock._id
        );
        this.isBlockDialogVisible = false;
      } catch (error) {
        console.log(error);
      }
    },
    onCancelUnblock() {
      this.isBlockDialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.blocked-users-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  .users-list-wrapper {
    height: 100%;
    overflow-y: scroll;
  }

  .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;

    .v-icon {
      font-size: 48px;
    }

    span {
      font-size: 24px;
      margin-top: 16px;
    }
  }
}
</style>
