<template>
  <div class="events-list-page d-flex flex-column px-md-10 pt-md-7 ml-md-10">
    <div class="empty-list" v-if="!isLoading && !events.length">
      <v-icon>calendar-blank-outline</v-icon>
      <div>{{ $t('cardText.noEventsInArchive') }}</div>
    </div>
    <div class="events-list" v-else-if="!isLoading">
      <v-row id="eventsList" class="d-flex" :class="{ 'py-3': events.length }">
        <v-col v-for="event in events" :key="event.id" cols="12" md="4" lg="3" class="pt-0 pb-0 pb-md-6">
          <EventListItem :item="event" class="my-3 py-3 px-1" />
        </v-col>
      </v-row>
    </div>
    <div class="skeleton-loader" v-else-if="isLoading">
      <v-skeleton-loader v-for="i in 10" :key="i" color="background" :height="150" type="list-item-avatar-three-line" />
    </div>
  </div>
</template>

<script>
import EventListItem from '@/components/EventListItem.vue';

export default {
  name: 'EventsArchivePage',
  components: {
    EventListItem,
  },
  data() {
    return {
      isLoading: false,
      events: [],
    };
  },
  async mounted() {
    const userId = this.$route.params.id;
    const archiveCached = this.$store.getters["EventStore/getEventArchive"](userId);

    if (archiveCached.length) {
      this.events = archiveCached;
      return;
    }

    try {
      this.events = await this.$store.dispatch('EventStore/fetchArchiveEventsList', userId);
    }
    catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.events-list-page {
  height: 100%;
  padding: 0 12px;

  @media (min-width: 960px) {
    overflow-y: scroll;
    
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(var(--v-theme-primary));
      border-radius: 10px;
      border: 1px solid transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    scrollbar-color: rgb(var(--v-theme-primary)) transparent;
    scrollbar-width: thin;
  }

  .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #9e9e9e;
    font-size: 20px;
    font-weight: 500;
    gap: 10px;

    .v-icon {
      font-size: 50px;
    }
  }
}
</style>
