<template>
    <div class="user-card-list-item d-flex flex-md-column my-0 py-3 px-md-5 py-md-5">
        <div class="user-avatar d-flex d-md-block text-md-center pt-1 me-6 me-md-0" @click="openProfile">
            <img v-if="item.avatar" :src="item.avatar" alt="avatar" />
            <div class="avatar-placeholder" v-else>
                <v-icon>mdi-account-circle</v-icon>
            </div>
            <div class="controls d-md-flex d-none" v-if="!item.isDeleted && !isBlockedByMe">
                <BottomMenu :menuItems="menuItems" />
            </div>
        </div>
        <div class="user-info d-flex flex-column justify-space-between" @click="openProfile">
            <div class="name d-flex">
                <span>{{ item.name }}</span>
                <div class="ps-2 confirmed-icon" v-if="item.isPhoneVerified">
                    <ConfirmedUserSvg />
                </div>
            </div>
        </div>
        <div class="controls d-flex" v-if="!item.isDeleted && !isBlockedByMe">
            <div class="d-md-none d-flex">
                <BottomMenu :menuItems="menuItems" />
            </div>
            <BaseButton class="follow-button" v-if="item._id !== currentUserId && !item.isIncomingUser" :max-width="110"
                :class="{ primary: !isFollowing }" @click="changeFollowingStatus">
                <span v-if="isFollowing">{{ $t('button.following') }}</span>
                <span v-else>{{ $t('button.follow') }}</span>
            </BaseButton>
            <div v-else class="empty-controls"></div>
        </div>
        <div class="blocked-label" v-else-if="isBlockedByMe">
            {{ $t('label.blocked') }}
        </div>
        <ReportDialog v-model="isReportDialogVisible" :name="item.name" :user-id="item._id" />
        <YesNoDialog v-model="isBlockDialogVisible" :dialog-text="$t('dialog.sureToBlock', { name: item.name })"
            @confirm="blockUser" @cancel="onCancelBlock" :is-cancel-red="false" :is-confirm-red="true" />
    </div>
</template>
  
  <script>
  import BottomMenu from '@/components/BottomMenu.vue';
  import BaseButton from '@/components/base/BaseButton.vue';
  import ConfirmedUserSvg from '@/components/icons/ConfirmedUserSvg.vue';
  import SharingUtils from '@/utils/SharingUtils';
  import ReportDialog from '@/components/views/ReportDialog.vue';
  import YesNoDialog from '@/components/views/YesNoDialog.vue';
  import { landing, webApp } from '@/constants/urls';
  
  export default {
    name: 'UserCardListItem',
    components: {
      ReportDialog,
      BaseButton,
      BottomMenu,
      YesNoDialog,
      ConfirmedUserSvg,
    },
    props: {
      item: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        isButtonLoading: false,
        isBlockDialogVisible: false,
        isReportDialogVisible: false,
        isRejectDialogVisible: false
      };
    },
    computed: {
      isFollowing() {
        return this.$store.getters['UserStore/isFollowing'](this.item._id);
      },
      isBlockedByMe() {
        return this.$store.getters['UserStore/isBlockedByMe'](this.item._id);
      },
      currentUserId() {
        return this.$store.getters['UserStore/getUserId'];
      },

      age() {
        return this.item.dateOfBirth
          ? new Date().getFullYear() -
          new Date(this.item.dateOfBirth).getFullYear()
          : 18;
      },
      menuItems() {
        const menuItems = [
          {
            title: this.$t('menuItem.shareAccount', { name: this.item.name }),
            icon: 'mdi-share-variant',
            color: '#FFFFFF',
            isVisible: !this.item.isIncomingUser,
            handler: () => {
              const url = `${this.linkBase}/${this.linkRoute}/${this.item._id}`;
              SharingUtils.share({
                url,
                title: 'Share account',
                text: this.item.name,
              });
            },
          },
          {
            title: this.$t('menuItem.reportProfile', { profileName: this.item.name }),
            icon: 'mdi-flag-outline',
            isVisible: (this.item._id !== this.currentUserId) && !this.item.isIncomingUser,
            color: '#D63B3B',
            handler: () => {
              this.showReportDialog();
            },
          },
          {
            title: this.$t('menuItem.block', { name: this.item.name }),
            icon: 'mdi-block-helper',
            color: '#D63B3B',
            isVisible: (this.item._id !== this.currentUserId) && !this.item.isIncomingUser,
            handler: () => {
              this.showBlockDialog();
            },
          },
        ];
  
        return menuItems.filter((item) => item.isVisible);
      },
      isMobile() {
        return !!window.cordova;
      },
      linkBase() {
        return this.isMobile ? landing : webApp;
      },
      linkRoute() {
        return this.isMobile ? 'profileId' : 'profile';
      },
    },
    methods: {
      showReportDialog() {
        this.isReportDialogVisible = true;
      },
      showBlockDialog() {
        this.isBlockDialogVisible = true;
      },
      async blockUser() {
        this.isBlockDialogVisible = false;
        await this.$store.dispatch('UserStore/blockUser', this.item._id);
        await this.$store.dispatch('UserStore/refreshUser');
        this.$emit('blockedUser');
      },
      onCancelBlock() {
        this.isBlockDialogVisible = false;
      },
      async changeFollowingStatus() {
        if (this.isButtonLoading || !this.item._id) {
          return;
        }
  
        try {
          this.isButtonLoading = true;
          await this.$store.dispatch('UserStore/changeFollowingStatus', {
            userId: this.item._id,
          });
          this.isButtonLoading = false;
        } catch (error) {
          console.error(error);
        }
      },
      openProfile() {
        if (this.disableRedirect || this.item.isIncomingUser) {
          return;
        }
  
        this.$router.push({ name: 'profileId', params: { id: this.item._id } });
      },
    },
  };
  </script>
  
<style lang="scss">
.user-card-list-item {
    border-bottom: 1px solid rgb(var(--v-theme-mako));
    padding-bottom: 16px;
    cursor: pointer;

    @media (min-width: 960px) {
        border: 1px rgb(var(--v-theme-primary)) solid;
        border-radius: 22px;
        background-color: rgba(var(--v-theme-abbey), 0.1);
        height: 100%;

        &:hover {
            background-color: rgba(var(--v-theme-abbey-hover), 0.1);
        }
    }

    &:last-of-type {
        @media (max-width: 960px) {
            border-bottom: none;
        }
    }

    .rating {
        svg {
            fill: #cccccc;
        }
    }

    .user-avatar {
        img {
            height: 72px;
            width: 72px;
            border-radius: 15%;
            object-fit: cover;

            @media (min-width: 960px) {
                height: 150px;
                width: 150px;
            }

            @media (min-width: 1280px) {
                height: 120px;
                width: 120px;
            }

            @media (min-width: 1400px) {
                height: 150px;
                width: 150px;
            }
        }

        .avatar-placeholder i {
            font-size: 72px;
            opacity: 0.5;

            @media (min-width: 960px) {
                font-size: 150px;
            }

            @media (min-width: 1280px) {
                font-size: 120px;
            }

            @media (min-width: 1400px) {
                font-size: 150px;
            }

            & {
                background-color: rgba(0, 0, 0, 0.25);
                border-radius: 15%;
            }
        }

        .controls {
            align-items: end;
        }
    }

    .follow-button {
        @media (min-width: 960px) {
            max-width: 100% !important;
        }
    }

    .phone-number {
        font-size: 14px;
        color: #d0d0d0;
    }

    .blocked-label {
        color: rgb(var(--v-theme-error));
    }

    .empty-controls {
        width: 100px;
    }

    .request-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon-control-wrapper {
            display: flex;
            cursor: pointer;
            padding: 8px;
            border-radius: 12px;

            &.accept {
                border: 1px solid #6fcf97;

                svg {
                    fill: #6fcf97;
                }
            }

            &.reject {
                border: 1px solid #e57373;

                svg {
                    fill: #e57373;
                }
            }
        }
    }

    .user-info {
        flex-grow: 3;

        .name,
        .age,
        .location,
        .rating {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
        }

        .name {
            align-items: normal;
        }

        .confirmed-icon {
            svg {
                height: 24px;
                width: 24px;
                stroke: #FFFFFF;
                fill: #6C7FCE
            }
        }

        .name {
            font-weight: 500;

            @media (min-width: 960px) {
                font-size: 18px;
            }
        }

        .age,
        .location,
        .rating {
            padding-top: 2px;
            line-height: 20px;
            color: #d0d0d0;
        }
    }

    .controls {
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        @media (min-width: 960px) {
            align-items: center;
        }

        .v-icon {
            transform: rotate(90deg);
            color: rgb(var(--v-theme-grey-icon));
        }

        .v-btn {
            font-weight: 400;
            margin-top: 12px;

            @media (min-width: 960px) {
                font-size: 18px;
            }
        }
    }
}
</style>
  
