<template>
  <BaseDialog class="qr-dialog" v-model="isDialogActive">
    <v-card class="pb-8 px-8 qr-block w-md-25 my-md-0 mx-md-auto">
      <v-card-title class="py-4 text-center">{{ label }}</v-card-title>
      <BaseImg :image="qrCode" class="qr-image d-flex justify-center pb-4" />
      <div class="dialog-actions">
        <BaseButton @click="share"> {{ $t('button.share') }}</BaseButton>
      </div>
    </v-card>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseImg from '@/components/base/BaseImg.vue';
import SharingUtils from '@/utils/SharingUtils';

export default {
  name: 'QrDialog',
  components: {
    BaseImg,
    BaseDialog,
    BaseButton,
  },
  props: {
    qrCode: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    }
  },
  data() {
    const dialogLabel = this.label || this.$t('cardText.eventURLQR');
    return {
      dialogLabel,
      isDialogActive: this.modelValue,
    };
  },
  watch: {
    modelValue(value) {
      this.isDialogActive = value;
    },
    isDialogActive(value) {
      this.$emit('update:modelValue', value);
    },
  },
  methods: {
    async share() {
      const blob = await (await fetch(this.qrCode)).blob();
      const file = new File([blob], `eventQrCode-${Date.now()}.png`, {
        type: blob.type,
      });
      SharingUtils.share({
        files: [file],
      });
      this.isDialogActive = false;
    },
  },
};
</script>

<style lang="scss"></style>
