<template>
  <div class="event-list-item d-flex flex-md-column justify-space-between justify-md-start  my-2 py-2 px-md-5 py-md-5" @click="showEvent(item)"
  :class="{ disabled: isNotAllowedToJoin }">
    <div class="section d-flex flex-md-column">
      <div class="image-block me-4 me-md-0">
        <img v-if="!!item.image" :src="item.image" />
        <img v-else :src="placeholderUrl" />
      </div>
      <div class="item-info-block">
        <div class="d-flex mb-2 align-end">
          <div class="name mr-2">{{ item.name }}</div>
          <ClubSvg v-if="item.isClubEvent" />
        </div>
        <div class="game-name">
          <DiceSvg />
          <div>{{ item.gameName }}</div>
        </div>
        <div class="location">
          <LocationSvg />
          <div>{{ item.locationText }}</div>
        </div>
        <div class="date">
          <CalendarSvg />
          <div>
            {{ formatDate(item.date) }}, {{ formatTime(item.startsAt) }}
          </div>
        </div>
        <div class="filters-section mt-3" v-if="hasFilters">
          <SmokingFilter v-if="item.noSmoking" class="me-2" />
          <AgeFilter v-if="item.noKids" class="me-2" />
          <AlcoholFilter v-if="item.noAlcohol" class="me-2" />
          <PetsFilter v-if="item.noPets" />
        </div>
      </div>
    </div>
    <div class="additional-block d-flex flex-column justify-space-between">
      <div class="section">
        <div class="duration">
          <ClockSvg />
          <div>{{ formatPlayTimeRange(item) }}</div>
        </div>
        <div class="participants">
          <CommunitySvg />
          <div>{{ item.players?.length }} / {{ item.maxPlayers }}</div>
        </div>
      </div>

      <div class="section" v-if="item.isStarted && !item.isEnded">
        <div>{{ $t("label.inProgress") }}</div>
        <div class="progress-wrapper">
          <div
            class="event-progress"
            :style="{ width: `${eventProgress}%` }"
          ></div>
        </div>
      </div>
    </div>
    <BaseDialog v-model="isDialogVisible" @close="isDialogVisible = false" class="verify-dialog">
      <v-card class="py-8 px-4">
        <div class="not-verified-icon-wrapper d-flex justify-center">
          <ConfirmedUserSvg />
        </div>
        <v-card-text class="pt-6 pb-6 px-4 popup-title text-center">{{ $t('cardText.verifiedUsersOnly') }}</v-card-text>
        <BaseButton @click="openSecuritySettings" class="primary">{{ $t('button.openSecuritySettings') }}</BaseButton>
      </v-card>
    </BaseDialog>
  </div>
</template>

<script>
import DiceSvg from '@/components/icons/DiceSvg.vue';
import CommunitySvg from '@/components/icons/CommunitySvg.vue';
import LocationSvg from '@/components/icons/LocationSvg.vue';
import ClockSvg from '@/components/icons/ClockSvg.vue';
import CalendarSvg from '@/components/icons/CalendarSvg.vue';
import SmokingFilter from '@/components/icons/SmokingFilter.vue';
import AgeFilter from '@/components/icons/AgeFilter.vue';
import AlcoholFilter from '@/components/icons/AlcoholFilter.vue';
import PetsFilter from '@/components/icons/PetsFilter.vue';
import dateUtils from '@/utils/dateUtils';
import BaseDialog from '@/components/base/BaseDialog.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import ConfirmedUserSvg from '@/components/icons/ConfirmedUserSvg.vue';
import ClubSvg from './icons/ClubSvg.vue';

export default {
  name: "EventListItem",
  components: {
    DiceSvg,
    ClockSvg,
    AgeFilter,
    BaseDialog,
    PetsFilter,
    BaseButton,
    LocationSvg,
    CalendarSvg,
    CommunitySvg,
    SmokingFilter,
    AlcoholFilter,
    ConfirmedUserSvg,
    ClubSvg
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDialogVisible: false,
    };
  },
  methods: {
    formatDate(date) {
      return dateUtils.formatDate(date, this.$i18n.locale);
    },
    formatTime(isoDate) {
      return new Date(isoDate).toTimeString().substring(0, 5);
    },
    formatPlayTimeRange(item) {
      if (item.playTimeRange?.[0] === item.playTimeRange?.[1]) {
        return "~ " + item.playTimeRange?.[0] + " m";
      } else {
        return item.playTimeRange?.[0] + " - " + item.playTimeRange?.[1] + " m";
      }
    },
    async showEvent(item) {
      if (this.isNotAllowedToJoin) {
        this.isDialogVisible = true;
        return;
      }


      await this.$store.dispatch('EventStore/setSelectedEvent', item);
      this.$router.push({ name: 'event', params: { id: item._id } });
    },
    openSecuritySettings() {
      this.$router.push({ name: 'securitySettings' });
    },
  },
  computed: {
    hasFilters() {
      return (
        this.item.noSmoking ||
        this.item.noKids ||
        this.item.noAlcohol ||
        this.item.noPets
      );
    },
    isNotAllowedToJoin() {
      return this.item.verifiedUsersOnly && (!this.isCurrentUserVerified && !this.isCreatedByCurrentUser)
    },
    eventProgress() {
      if (this.item.isStarted && !this.item.isEnded) {
        const now = new Date();
        const start = new Date(this.item.startsAt);
        const end = new Date(this.item.endsAt);
        const progress = ((now - start) / (end - start)) * 100;
        return progress > 100 ? 100 : progress;
      } else if (this.item.isEnded) {
        return 100;
      } else {
        return 0;
      }
    },
    placeholderUrl() {
      return require("@/assets/images/board-game-placeholder.png");
    },
    isCreatedByCurrentUser() {
      return this.$store.getters['UserStore/getUser']?._id === this.item.creator;
    },
    isCurrentUserVerified() {
      return this.$store.getters['UserStore/getIsPhoneVerified'];
    }
  },
};
</script>

<style lang="scss">
.not-verified-icon-wrapper {
  svg {
    width: 80px;
    height: 80px;
    fill: rgb(var(--v-theme-background));
    stroke: #6b6b6b;
  }
}

.event-list-item {
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid rgb(var(--v-theme-mako));
  }

  &.disabled {
    opacity: 0.5;
  }
  

  @media (min-width: 960px) {
    border: 1px rgb(var(--v-theme-primary)) solid;
    border-radius: 22px;
    background-color: rgba(var(--v-theme-abbey), 0.1);
    height: 100%;

    &:hover {
      background-color: rgba(var(--v-theme-abbey-hover), 0.1);
    }
  }

  img,
  .image-placeholder {
    width: 72px;
    height: 72px;
    object-fit: cover;
    border-radius: 16px;

    @media (min-width: 960px) {
      width: 100%;
      height: 200px;
      margin-bottom: 10px;
    }
  }

  .image-placeholder {
    background: #23242c;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 52px;
      height: 52px;
    }
  }

  .additional-block {
    min-width: 90px;
  }

  .item-info-block {
    .name {
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 600;
    }

    .game-name,
    .date,
    .location {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      color: #cccccc;
      line-height: 0.9rem;
      margin: 4px 0;

      svg {
        min-width: 16px;
        min-height: 16px;
        margin-right: 4px;
      }
    }

    .location {
      text-wrap: balance;

      svg {
        min-height: 16px;
        min-width: 16px;
      }
    }
  }

  .duration,
  .participants,
  .likes {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    line-height: 0.9rem;
    font-weight: 300;
    margin: 4px 0;

    svg {
      margin-right: 4px;
      fill: #cccccc;
    }
  }

  .duration {
    text-wrap: nowrap;
  }

  .section {
    width: 100%;

    .progress-wrapper {
      width: 100%;
      height: 8px;
      background: rgb(var(--v-theme-mako));
      border-radius: 4px;

      .event-progress {
        height: 100%;
        background: rgb(var(--v-theme-primary));
        border-radius: 4px;
        text-align: center;
        line-height: 8px;
        color: white;
      }
    }
  }

  .filters-section {
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
      fill: white;
    }
  }
}
</style>
