<template>
  <div class="security-page px-3 px-md-16 py-md-7 ml-md-10">
    <div class="element-wrapper">
      <ResetPasswordForm :isChangePassword="true" />
      <ConfirmPhone />
    </div>
    <div class="element-wrapper delete-button pt-2 pb-4">
      <BaseButton class="cancel w-md-auto" @click="deleteAccountConfirmation">{{
        $t('button.deleteAccount')
      }}</BaseButton>
    </div>
    <YesNoDialog v-model="isDeleteDialogVisible" :dialog-text="deleteDialogText" persistent @confirm="deleteAccount"
      @cancel="cancelDelete" :is-cancel-red="false" :is-confirm-red="true">
    </YesNoDialog>
    <BaseDialog v-model="isDeleteProcessInAction" persistent>
      <div class="d-flex justify-center">
        <v-progress-circular color="#e57373" indeterminate></v-progress-circular>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import ResetPasswordForm from '@/components/views/ResetPasswordForm.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';
import ConfirmPhone from '@/components/views/ConfirmPhone.vue';
import YesNoDialog from '@/components/views/YesNoDialog.vue';
export default {
  name: 'SecuritySetting',
  components: {
    ResetPasswordForm,
    BaseDialog,
    BaseButton,
    ConfirmPhone,
    YesNoDialog,
    // BaseButton,
  },
  data() {
    return {
      isDeleteDialogVisible: false,
      isDeleteProcessInAction: false,
      deleteDialogText: this.$t('dialog.deleteAccountWarning'),
    };
  },
  methods: {
    cancelDelete() {
      this.isDeleteDialogVisible = false;
    },
    async deleteAccount() {
      try {
        this.isDeleteProcessInAction = true;
        const isDeleted = await this.$store.dispatch('UserStore/deleteAccount');

        if (isDeleted) {
          await this.$store.dispatch('UserStore/logout', isDeleted);
          this.isDeleteProcessInAction = false;
          this.$router.push({ name: 'login' });
        }
      } catch (error) {
        console.log(error);
      }
    },
    deleteAccountConfirmation() {
      this.isDeleteDialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.security-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  .delete-button {
    margin-top: auto;
  }
}
</style>
