import ServerRequest from '@/utils/ServerRequest';

const state = () => {
  const savedUser = localStorage.getItem('user');
  return {
    user: savedUser ? JSON.parse(savedUser) : null,
    coordinates: null,
    location: null,
    currentLocation: null,
    currentCoordinates: null,
    users: new Map(),
    signUpData: null,
    usersPageList: [],
    usersInviteList: [],
    notifications: [],
    favoriteGames: [],
    hostedEvents: [],
    joinedEvents: [],
    favoriteByUserIds: new Map(),
    socials: null,
    isLoggedIn: !!localStorage.getItem('user'),
    shouldOpenIdentityForm: !!localStorage.getItem('shouldOpenIdentityForm'),
    isPhoneVerificationStarted: false,
    phoneVerificationStartTime: null,
  };
};

const mutations = {
  setUser(state, user) {
    state.user = user;
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
    state.isLoggedIn = !!user;
  },

  setIsPhoneVerificationStarted(state, value) {
    state.isPhoneVerificationStarted = value;
  },

  setPhoneVerificationStartTime(state, value) {
    state.phoneVerificationStartTime = value;
  },

  setShouldOpenIdentityForm(state, value) {
    state.shouldOpenIdentityForm = value;
  },

  setUsersInviteList(state, users) {
    state.usersInviteList = users;
  },

  setUsersPageList(state, users) {
    state.usersPageList = users;
  },

  setSignUpData(state, data) {
    state.signUpData = data;
  },

  setNotifications(state, notifications) {
    state.notifications = notifications;
  },

  setFollowing(state, following) {
    state.user.following = following;
  },

  setFollowers(state, followers) {
    state.user.followers = followers;
  },

  setUserById(state, { userId, user }) {
    if (!state.users.has(userId)) {
      state.users.set(userId, user);
    }
  },

  setFavoriteGames(state, favoriteGames) {
    state.favoriteGames = favoriteGames || [];
  },

  setHostedEvents(state, hostedEvents) {
    state.hostedEvents = hostedEvents || [];
  },

  setJoinedEvents(state, joinedEvents) {
    state.joinedEvents = joinedEvents || [];
  },

  setFavoriteByUserId(state, { userId, favoriteGames }) {
    state.favoriteByUserIds.set(userId, favoriteGames || []);
  },

  setCoordinates(state, coordinates) {
    state.coordinates = coordinates;
  },

  setCurrentCoordinates(state, coordinates) {
    state.currentCoordinates = coordinates;
  },

  setCurrentLocation(state, location) {
    state.currentLocation = location;
  },

  setLocation(state, location) {
    state.location = location;
  },

  setSocials(state, socials) {
    state.socials = socials;
  },
};

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  shouldOpenIdentityForm: (state) => state.shouldOpenIdentityForm,
  getUser: (state) => state.user,
  getUserId: (state) => state.user._id,
  isFollowing: (state) => (userId) => {
    return state.user.following.includes(userId);
  },
  getSignUpData: (state) => state.signUpData,
  getBlockedUsers: (state) => state.user.blocked || [],
  getUsersInviteList: (state) => state.usersInviteList,
  getCoordinates: (state) => state.coordinates,
  getLocation: (state) => state.location,
  getCurrentCoordinates: (state) => state.currentCoordinates,
  getCurrentLocation: (state) => state.currentLocation,
  getNotifications: (state) => state.notifications,
  getFavoriteGames: (state) => state.favoriteGames,
  getHostedEvents: (state) => state.hostedEvents,
  getJoinedEvents: (state) => state.joinedEvents,
  getIsPhoneVerified: (state) => !!state.user.phone,
  getIsPhoneVerificationStarted: (state) => state.isPhoneVerificationStarted,
  getPhoneVerificationStartTime: (state) => state.phoneVerificationStartTime,
  isGameFavorite: (state) => (gameId) => {
    return state.favoriteGames.includes(gameId);
  },
  isBlockedByMe: (state) => (userId) => {
    return state.user.blocked?.includes?.(userId) || false;
  },
  getUserById: (state) => (userId) => state.users.get(userId),
  getUsersPageList: (state) => state.usersPageList,
  isCurrentUsersProfile: (state) => (userId) => {
    return state.user._id === userId;
  },
  getFavoriteByUserId: (state) => (userId) =>
    state.favoriteByUserIds.get(userId) || [],
  getSocials: (state) => state.socials,
  getCountry: (state) => {
    const country = state.user?.country;
    return country || null;
  },
};

const actions = {
  async fetchLocation({ commit, getters }) {
    try {
      if (getters.getLocation && getters.getCoordinates) {
        return true;
      }

      await new Promise((resolve, reject) => {
        if (!window.cordova) {
          resolve();
          return;
        }

        document.addEventListener('deviceready', resolve, false);
      });

      const coordObj = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          timeout: 5000,
        });
      });
      const { latitude: lat, longitude: lng } = coordObj.coords;

      commit('setCurrentCoordinates', {
        lat,
        lng,
      });
      commit('setCoordinates', {
        lat,
        lng,
      });

      const { location } = await ServerRequest.get('search', 'location', {
        lat,
        lng,
      });
      commit('setLocation', location);
      commit('setCurrentLocation', location);
      return true;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async fetchUsersByQuery({ commit }, { query }) {
    try {
      const response = await ServerRequest.get('users', 'search', {
        name: query,
      });
      const users = response.users;

      users.forEach((user) => {
        commit('setUserById', { userId: user._id, user });
      });

      return response.users;
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  setUsersPageList({ commit, getters }, users) {
    const currentUser = getters.getUser;
    const blockedIds = [
      ...(currentUser.blocked || []),
      ...(currentUser.blockedBy || []),
    ];
    const usersList = users.filter((user) => !blockedIds.includes(user));
    commit('setUsersPageList', usersList);
  },
  async fetchUsersList({ commit, getters }, userIds) {
    try {
      const cachedUsers = userIds
        .map((userId) => getters['getUserById'](userId))
        .filter((user) => !!user);

      if (cachedUsers.length === userIds.length) {
        return cachedUsers;
      }

      const rest = userIds.filter(
        (userId) => !cachedUsers.find((user) => user._id === userId)
      );

      const st = getters.getUser.sessionToken;
      const response = await ServerRequest.get('users', 'getUsersList', {
        userIds: rest,
        st,
      });
      const restUsers = response.users;
      restUsers.forEach((user) => {
        commit('setUserById', { userId: user._id, user });
      });

      return [...cachedUsers, ...restUsers];
    } catch (error) {
      console.log(error);
    }
  },
  async verifyPhone({ getters }, { phone }) {
    const st = getters.getUser.sessionToken;
    const result = await ServerRequest.post('users', 'verifyPhone', {
      st,
      phone,
    });
    return result.verificationCreated;
  },

  async checkVerificationCode({ getters, commit }, { phone, code }) {
    const st = getters.getUser.sessionToken;
    const result = await ServerRequest.post('users', 'checkVerificationCode', {
      st,
      phone,
      code,
    });

    if (result.isVerified) {
      const user = getters.getUser;
      commit('setUser', { ...user, phone });
    }

    return result.isVerified;
  },

  async createResetPasswordTask(context, { email }) {
    return ServerRequest.post('auth', 'createResetPasswordTask', { email });
  },
  async checkResetConfirmationCode(context, { email, code }) {
    return ServerRequest.post('auth', 'checkResetConfirmationCode', {
      code,
      email,
    });
  },

  async resetPassword(
    { getters },
    { email, code, password, isChangePassword = false }
  ) {
    if (isChangePassword) {
      const st = getters.getUser.sessionToken;
      return ServerRequest.post('users', 'changePassword', { st, password });
    } else {
      return ServerRequest.post('auth', 'resetPassword', {
        email,
        code,
        password,
      });
    }
  },

  async reportProfile({ getters }, { userId, reason }) {
    try {
      const st = getters.getUser.sessionToken;
      const result = await ServerRequest.post('users', 'reportProfile', {
        st,
        userId,
        reason,
      });
      return result.isReported;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  async blockUser({ getters, dispatch }, userId) {
    try {
      const st = getters.getUser.sessionToken;
      const result = await ServerRequest.post('users', 'blockUser', {
        st,
        userId,
      });

      if (result.isBlocked) {
        dispatch('SearchStore/removeUserFromSearch', userId, { root: true });
      }

      return result.isBlocked;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  async unblockUser({ getters }, userId) {
    try {
      const st = getters.getUser.sessionToken;
      const result = await ServerRequest.post('users', 'unblockUser', {
        st,
        userId,
      });
      return result.isUnblocked;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  async canEmailBeRegistered(context, email) {
    const result = await ServerRequest.get('auth', 'canEmailBeRegistered', {
      email,
    });
    return result.canBeRegistered;
  },

  async fetchEventsByUserId({ commit, getters }, { userId }) {
    const st = getters.getUser.sessionToken;
    const { hosted, joined } = await ServerRequest.get(
      'users',
      'getEventsByUserId',
      { userId, st }
    );

    commit('setHostedEvents', hosted);
    commit('setJoinedEvents', joined);
  },
  restoreCurrentLocation({ commit, getters }) {
    const location = getters.getCurrentLocation;
    const coordinates = getters.getCurrentCoordinates;
    commit('setLocation', location);
    commit('setCoordinates', coordinates);
  },
  async fetchNotifications({ getters, commit }) {
    try {
      const user = getters.getUser;
      const st = user.sessionToken;

      const result = await ServerRequest.get('users', 'getNotifications', {
        st,
      });
      commit('setNotifications', result?.notifications || []);
    } catch (error) {
      console.log(error);
    }
  },
  async updateLocation({ commit, state }, { placeId }) {
    if (!state.user) {
      return;
    }
    const { location } = await ServerRequest.get(
      'search',
      'getCoordinatesById',
      { placeId }
    );
    const { coordinates, city, postalCode } = location;

    commit('setCoordinates', coordinates);
    commit('setLocation', { city, postalCode });
  },
  async updateSocialSettings({ rootGetters, getters, commit }, socialSettings) {
    const userId = rootGetters['UserStore/getUserId'];
    const st = getters.getUser.sessionToken;
    const result = await ServerRequest.post('users', 'updateSocialSettings', {
      userId,
      socialSettings,
      st
    });
    commit('setSocials', result.socialSettings || null);
    return !!result.socialSettings;
  },
  async fetchSocialSettings({ commit, getters }, { userId } = {}) {
    const user = getters.getUser;
    const result = await ServerRequest.get('users', 'getSocialSettings', {
      userId: userId || user._id,
    });
    commit('setSocials', result.socialSettings || null);
  },

  async deleteAccount({ getters }) {
    try {
      const st = getters.getUser.sessionToken;
      const result = await ServerRequest.post('users', 'deleteProfile', { st });
      localStorage.clear();
      return result.isDeleted;
    } catch (error) {
      console.log(error);
    }
  },

  async login({ commit }, userCredentials) {
    const result = await ServerRequest.post('auth', 'login', userCredentials);

    if (result.isLoggedIn) {
      localStorage.setItem('user', JSON.stringify(result.user));
      commit('setUser', result.user);
    }

    return result.isLoggedIn;
  },
  async setUserAvatar({ commit, state }, { userId, avatar }) {
    const currentUser = state.user;
    if (currentUser._id === userId) {
      await ServerRequest.post('users', 'setAvatar', { userId, avatar });
      commit('setUser', { ...currentUser, avatar });
    }
  },
  async getUserById({ commit, getters }, userId) {
    const { user } = await ServerRequest.get('users', 'getUserById', {
      userId,
    });
    commit('setUserById', { userId, user });
  },
  async webGoogleAuth({ commit }, { accessToken }) {
    const result = await ServerRequest.post('auth', 'webGoogleAuth', {
      accessToken,
    });

    if (result.isLoggedIn) {
      localStorage.setItem('user', JSON.stringify(result.user));
      commit('setUser', result.user);
    }

    return result.isLoggedIn;
  },
  async signUp({ commit }, userCredentials) {
    const result = await ServerRequest.post('auth', 'signUp', userCredentials);
    if (result.user) {
      localStorage.setItem('user', JSON.stringify(result.user));
      commit('setUser', result.user);

      if (result.isNewUser) {
        localStorage.setItem('shouldOpenIdentityForm', true);
        commit('setShouldOpenIdentityForm', true);
      }

      return true;
    }
    return false;
  },
  async changeFollowingStatus({ commit, state, getters }, { userId }) {
    const currentUser = state.user;
    const st = getters.getUser.sessionToken;
    const isFollowing = currentUser.following.includes(userId);
    try {
      let response;
      if (isFollowing) {
        response = await ServerRequest.post('users', 'unfollow', {
          userId: currentUser._id,
          personToUnfollowId: userId,
          st
        });
      } else {
        response = await ServerRequest.post('users', 'follow', {
          userId: currentUser._id,
          personToFollowId: userId,
          st
        });
      }
      commit('setFollowing', response.following);
    } catch (error) {
      console.log(error);
    }
  },

  async updateUser({ commit, state, getters }, newUserData) {
    const currentUser = state.user;
    const st = getters.getUser.sessionToken;
    const response = await ServerRequest.post('users', 'updateUser', {
      userId: currentUser._id,
      user: newUserData,
      st
    });
    commit('setUser', { ...currentUser, ...newUserData });
    return response.isUpdated;
  },

  async refreshFavoriteGames({ commit, state }) {
    if (state.favoriteGames.length || !state.user) {
      return;
    }
    const favoriteGames = await ServerRequest.get('users', 'getFavoriteGames', {
      userId: state.user._id,
    });
    commit('setFavoriteGames', favoriteGames);
  },

  async fetchFavoriteGames({ commit, getters }, { userId }) {
    if (getters.getFavoriteByUserId(userId).length) {
      return;
    }
    const favoriteGames = await ServerRequest.get('users', 'getFavoriteGames', {
      userId,
    });
    commit('setFavoriteByUserId', { userId, favoriteGames });
  },

  async refreshUser({ commit }) {
    const localStorageUser = JSON.parse(localStorage.getItem('user') || '{}');
    if (localStorageUser && localStorageUser.sessionToken) {
      const { user } = await ServerRequest.get('auth', 'refresh', {
        sessionToken: localStorageUser.sessionToken,
      });
      if (user && user?._id) {
        localStorage.setItem('user', JSON.stringify(user));
        commit('setUser', user);
      } else {
        localStorage.removeItem('user');
        commit('setUser', null);
      }
    }
  },

  async loginWithApple({ commit }, userCredentials) {
    const result = await ServerRequest.post(
      'auth',
      'loginWithApple',
      userCredentials
    );
    if (result.isLoggedIn) {
      localStorage.setItem('user', JSON.stringify(result.user));
      commit('setUser', result.user);
    }

    if (result.isNewUser) {
      localStorage.setItem('shouldOpenIdentityForm', true);
      commit('setShouldOpenIdentityForm', true);
    }
    return { isLoggedIn: result.isLoggedIn, isNewUser: result.isNewUser };
  },

  async loginWithGoogle({ commit }, userCredentials) {
    const result = await ServerRequest.post(
      'auth',
      'loginWithGoogle',
      userCredentials
    );
    if (result.isLoggedIn) {
      localStorage.setItem('user', JSON.stringify(result.user));
      commit('setUser', result.user);
    }

    if (result.isNewUser) {
      localStorage.setItem('shouldOpenIdentityForm', true);
      commit('setShouldOpenIdentityForm', true);
    }

    return { isLoggedIn: result.isLoggedIn, isNewUser: result.isNewUser };
  },

  async logout({ commit, state }, isDeleted = false) {
    localStorage.removeItem('user');
    const sessionToken = state.user.sessionToken;
    if (!isDeleted) {
      await ServerRequest.get('auth', 'logout', { sessionToken });
    } else {
      localStorage.removeItem('shouldOpenIdentityForm');
      localStorage.removeItem('selectedLanguage');
    }
    commit('setUser', null);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
};
