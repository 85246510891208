<template>
  <div class="game-details-page px-3 px-md-10 py-md-2 ml-md-14">
    <div class="desktop-layout">
      <div class="left-pane">
        <GameItemTopBar :game="gameDetails" />
        <div class="game-info d-flex align-center">
          <div class="photo-block">
            <base-img v-if="isLoaded" class="thumbnail" :image="thumbnailUrl" alt="" loading="lazy" />
            <v-skeleton-loader v-else color="background" :width="108" :height="90" type="image"></v-skeleton-loader>
          </div>

          <div class="characteristics w-100 d-flex flex-column" v-if="isLoaded">
            <div class="info-item">
              <v-icon class="me-2" icon="mdi-account-group" />
              <span class="value">{{
                minPlayers === maxPlayers
                  ? `${maxPlayers}`
                  : `${minPlayers}-${maxPlayers}`
                }}</span>
            </div>
            <div class="info-item">
              <v-icon class="me-2" icon="mdi-clock-outline" />
              <span class="value">{{
                minPlayTime === maxPlayTime
                  ? `~ ${maxPlayTime}`
                  : `${minPlayTime}-${maxPlayTime}`
                }}</span>
            </div>
            <div class="info-item">
              <v-icon class="me-2" icon="mdi-human-male-boy" />
              <span class="value">{{ minAge }}+</span>
            </div>
            <div class="info-item">
              <a :href="`https://boardgamegeek.com/boardgame/${gameDetails.id}`" target="_blank" class="value link">{{
                $t('button.openBGG') }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="right-pane">
        <div class="label">{{ $t('label.description') }}</div>
        <div class="description-wrapper pt-2">
          <div class="description" v-html="description" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiUtils from '@/utils/ApiUtils';
import BaseImg from '@/components/base/BaseImg.vue';
import GameInfoView from '@/classes/GameInfoView.js';
import GameItemTopBar from '@/components/GameItemTopBar.vue';

export default {
  name: 'GameDetailsPage',
  components: {
    BaseImg,
    GameItemTopBar,
  },
  data() {
    const game = new GameInfoView();
    return {
      ...game,
      isLoaded: false,
      // images: [],
      gameDetails: {},
    };
  },

  async mounted() {
    const gameId = this.$route.params.id;
    const cachedGame = this.$store.getters['GameStore/getGameRules'](gameId);
    let gameDetails;

    if (cachedGame) {
      gameDetails = cachedGame;
    } else {
      const loadedGameRules = await ApiUtils.getGamesInfo(gameId);
      gameDetails = loadedGameRules[0];
    }

    this.gameDetails = gameDetails;
    this.thumbnailUrl = gameDetails.image;
    this.gameName = gameDetails.name;
    this.minPlayers = gameDetails.minPlayers;
    this.maxPlayers = gameDetails.maxPlayers;
    this.minPlayTime = gameDetails.minPlayTime;
    this.maxPlayTime = gameDetails.maxPlayTime;
    this.minAge = gameDetails.minAge;
    this.description = gameDetails.description.replaceAll('&#10;', '<br>');
    this.isLoaded = true;
  },
  methods: {},
};
</script>

<style lang="scss">
.game-details-page {
  height: calc(100% - 70px);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .desktop-layout {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (min-width: 960px) {
      flex-direction: row;
      height: 100%;
      overflow: hidden;
      align-items: baseline;
    }

    .left-pane {
      display: flex;
      flex-direction: column;

      @media (min-width: 960px) {
        flex: 1;
        align-items: center;
        height: 100%;
      }
    }

    .right-pane {
      min-height: 0;
      height: 100%;
      display: flex;
      flex-direction: column;

      @media (min-width: 960px) {
        flex: 3;
        padding: 10px;
        padding-left: 40px;
      }
    }
  }

  .photo-block {
    min-width: 108px;
    max-width: 108px;
    height: 108px;
    display: inline-block;
    margin-right: 15px;

    @media (min-width: 960px) {
      width: 100%;
      height: 200px;
      max-width: 100%;
      margin-right: 0;
    }

    .v-skeleton-loader__image {
      height: 108px !important;
    }
  }

  img {
    border-radius: 10px;
  }

  .game-info {
    @media (min-width: 960px) {
      display: flex;
      flex-direction: column;
      border: 1px rgb(var(--v-theme-primary)) solid;
      padding: 20px;
      border-radius: 22px;
      background-color: rgba(var(--v-theme-abbey), 0.1);
      height: 100%;
      justify-content: space-between;
      max-height: 400px;
      width: 100%;
    }
  }

  .gallery {
    overflow: auto;
    white-space: nowrap;
    display: inline-block;
    width: 100%;

    .photo-block {
      width: 108px;
      height: 108px;
      display: inline-block;
    }

    .thumbnail {
      object-fit: cover;
    }
  }

  .description-wrapper {
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0;
    width: 100%;
    height: 100%;
  }

  .description {
    text-align: justify;
  }

  .thumbnail {
    border-radius: 0px;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    object-fit: contain;
    display: inline-block;
    border-radius: 8px;

    @media (min-width: 960px) {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }

  .link {
    color: #a594fd;
    text-decoration: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .label {
    padding-bottom: 4px;
    margin-bottom: 8px;
    border-bottom: 1px solid #a7a7a7;
    font-weight: 600;
  }
}
</style>