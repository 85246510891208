<template>
  <BaseDialog
    class="add-participant-dialog"
    v-model="isVisible"
    max-width="600px"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5">{{
        $t("label.addParticipant")
      }}</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isFormValid">
          <BaseInput
            v-model="participant.name"
            :inputLabel="'label.name'"
            :rules="[rules.required]"
            required
          />
          <BaseInput
            v-model="participant.email"
            :inputLabel="'label.email'"
            :rules="[rules.email]"
          />
          <BaseInput 
            v-model="participant.phone" 
            :inputLabel="'label.phone'" 
            :rules="[rules.phone, rules.required]"
            required 
          />
          <BaseInput
            v-model="participant.socials"
            :inputLabel="'label.socials'"
          />
        </v-form>
      </v-card-text>

      <div class="dialog-actions">
        <BaseButton color="primary" :disabled="!isFormValid" @click="onSubmit">
          {{ $t("button.add") }}
        </BaseButton>
        <BaseButton color="primary" @click="onClose">
          {{ $t("button.cancel") }}
        </BaseButton>
      </div>
    </v-card>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/base/BaseDialog.vue";
import BaseInput from "@/components/base/BaseInput.vue";
import BaseButton from "@/components/base/BaseButton";

export default {
  name: "AddParticipantModal",
  components: {
    BaseDialog,
    BaseInput,
    BaseButton,
  },
  props: {
    modelValue: { type: Boolean, required: true },
    eventId: { type: String, required: true },
  },
  data() {
    return {
      isFormValid: false,
      participant: {
        name: "",
        email: "",
        phone: "",
        socials: "",
      },
      rules: {
        required: (value) => !!value || this.$t("error.required"),
        email: (value) =>
          !value || /.+@.+\..+/.test(value) || this.$t("error.invalidEmail"),
        phone: (value) => {
          const phoneRegex = /^\+?[0-9]{7,15}$/;
          return (
            phoneRegex.test(value) ||
            this.$t("error.invalidPhone")
          );
        },
      },
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    onSubmit() {
      if (!this.participant.email) {
        this.participant.email = `${this.participant.phone}@gmail.com`;
      }
      
      this.$emit("addParticipant", {
        eventId: this.eventId,
        participant: this.participant,
      });
      this.onClose();
    },
    onClose() {
      this.isVisible = false;
      this.resetForm();
    },
    resetForm() {
      this.participant = { name: "", email: "", phone: "", socials: "" };
      this.isFormValid = false;
    },
  },
};
</script>

<style lang="scss">
.add-participant-dialog {
  .dialog-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 20px 20px;

    .base-button {
      width: 45%;
      color: white;
    }
  }
}
</style>
