<template>
  <div class="copy-event-page">
    <CreateEventForm v-if="eventData" class="px-3" :eventData="eventData" />
  </div>
  </template>
  
  <script>
  import CreateEventForm from '@/components/CreateEventForm.vue';
  import { mapGetters } from 'vuex';
  
  export default {
    name: 'CopyEventPage',
    components: {
        CreateEventForm,
    },
    data() {
      return {
        eventData: null,
      };
    },
    computed: {
      ...mapGetters({
        event: 'EventStore/getSelectedEvent',
      }),
    },
    async mounted() {
      const eventId = this.$route.params.id;
      await this.$store.dispatch('EventStore/loadEvent', eventId);
      this.eventData = this.event;
    },
  };
  </script>
  
  <style lang="scss">

.copy-event-page {
  padding: 0 12px;

  @media (min-width: 960px) {
    padding: 0 100px;
  }
}
  // TODO: overview these styles - maybe move them to page-wrapper
  // .create-event-page {
  //   height: calc(
  //     100dvh - 140px - 12px - env(safe-area-inset-bottom) -
  //     env(safe-area-inset-top)
  //   );
  //   overflow-y: scroll;
  // }
  </style>

  