<template>
  <div class="users-list-page px-3 px-md-10 py-md-7 ml-md-12">
    <div class="empty-list" v-if="!isLoading && !users.length">
      <v-icon>mdi-account-off</v-icon>
      <div>{{ $t('cardText.noUsersFound') }}</div>
    </div>
    <v-container fluid class="users-list pt-3" v-else-if="!isLoading" @scroll="debouncedScrollHandler">
      <v-row id="usersList" lass="d-flex py-3">
        <v-col v-for="user in users" :key="user._id" cols="12" md="4" lg="2" class="pt-0 pb-0 pb-md-6">
          <UserCardListItem :item="user" class="my-md-3" />
        </v-col>
      </v-row>
    </v-container>
    <div class="skeleton-loader" v-else-if="isLoading">
      <v-skeleton-loader v-for="i in 10" :key="i" color="background" :height="150" type="list-item-avatar-three-line" />
    </div>
  </div>
</template>

<script>
import UserCardListItem from '@/components/UserCardListItem.vue';

export default {
  name: 'UsersListPage',
  components: {
    UserCardListItem,
  },
  data() {
    return {
      isLoading: false,
      users: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    const usersList = this.$store.getters['UserStore/getUsersPageList'];
    this.users = await this.$store.dispatch(
      'UserStore/fetchUsersList',
      usersList
    );
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.users-list-page {
  height: 100%;
  padding: 0 12px;

  .v-row>.v-col-md-4:not(:last-of-type) .user-card-list-item {
    border-bottom: 1px solid rgb(var(--v-theme-mako));
  
    @media (min-width: 960px) {
      border-bottom: 1px rgb(var(--v-theme-primary)) solid;
    }
  }

  @media (min-width: 960px) {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(var(--v-theme-primary));
      border-radius: 10px;
      border: 1px solid transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    scrollbar-color: rgb(var(--v-theme-primary)) transparent;
    scrollbar-width: thin;
  }

  .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #9e9e9e;
    font-size: 20px;
    font-weight: 500;
    gap: 10px;

    .v-icon {
      font-size: 50px;
    }
  }
}
</style>
