<template>
  <BaseDialog
    v-model="isActive"
    class="image-viewer-dialog"
    body-class="dark-overlay"
  >
    <div class="image-container">
      <v-btn
        icon
        class="close-btn d-none d-md-block"
        @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-img
        class="image-viewer"
        :src="imageUrl"
        max-width="100%"
        max-height="100%"
        @touchstart="touchStart"
        :id="id"
        @touchmove.prevent="handleSwipe"
        @touchend="touchEnd"
      ></v-img>
    </div>
  </BaseDialog>
</template>



<script>
import BaseDialog from '@/components/base/BaseDialog.vue';

export default {
  name: 'ImageViewer',
  components: {
    BaseDialog,
  },
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const id = `image-${Math.random().toString(36).substr(2, 9)}`;
    return {
      id,
      isActive: this.visible,
      isMovingImageAllowed: false,
      touchStartY: 0,
      touchEndY: 0,
    };
  },
  watch: {
    visible(value) {
      this.isActive = value;
    },

    isActive(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },
  methods: {
    closeDialog() {
      this.isActive = false;
    },
    touchStart(e) {
      e.preventDefault();
      this.isMovingImageAllowed = true;

      const image = document.getElementById(this.id);
      this.touchStartY = e.touches[0].screenY;
      image.style.transition = 'none';
    },
    touchEnd(e) {
      this.touchEndY = e.changedTouches[0].screenY;

      const deltaY = this.touchEndY - this.touchStartY;
      const image = document.getElementById(this.id);
      const swipeThreshold = image.getBoundingClientRect().height / 2; // Minimum distance for a swipe
      if (Math.abs(deltaY) > swipeThreshold) {
        this.isActive = false;
      } else {
        image.style.transition = 'transform 0.3s ease';
        image.style.transform = 'translate(0, 0)';
      }
      this.isMovingImageAllowed = false;
    },
    handleSwipe(e) {
      if (!this.isMovingImageAllowed) {
        return;
      }

      const touchMoveY = e.changedTouches[0].screenY;
      const deltaY = touchMoveY - this.touchStartY;
      const image = document.getElementById(this.id);
      image.style.transform = `translate(0px, ${deltaY}px)`;
    },
  },
};
</script>



<style lang="scss">
.image-viewer-dialog {
  .v-overlay__scrim {
    background: black;
    opacity: 0.5;
  }
}

body.dark-overlay {
  background-color: #18181f;
}

.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.close-btn {
  position: absolute;
  top: 2%;
  left: 6px;
  z-index: 10;
  background-color: rgb(var(--v-theme-primary)) !important;
  border-radius: 50%;
  padding: 8px;
  color: white;
}


@media (min-width: 768px) {
  .image-viewer-dialog {
    max-width: 20%;
    max-height: none;

    .image-viewer {
      border: 2px solid black;
      border-radius: 30px;
      box-shadow: 10px 10px 10px black;

    }

    .v-overlay__scrim {
      background: inherit;
      opacity: initial;
    }

  }
  .v-overlay--active.base-dialog {
      -webkit-backdrop-filter: blur(0);
      backdrop-filter: blur(0);
  }
}
</style>
