<template>
  <div class="reset-password-inputs">
    <BaseInput v-model="password" class="my-2" :append-inner-icon="isPasswordShown ? 'mdi-eye-off' : 'mdi-eye'"
      @click:append-inner="isPasswordShown = !isPasswordShown" :type="isPasswordShown ? 'text' : 'password'"
      :placeholder="placeholder" :inputLabel="inputLabel" />
    <small v-if="v$.password.$error" class="error pb-1">{{
      v$ && v$.password.$errors[0].$message
    }}</small>
    <BaseInput v-model="confirmPassword" class="my-2" :append-inner-icon="isConfirmShown ? 'mdi-eye-off' : 'mdi-eye'"
      @click:append-inner="isConfirmShown = !isConfirmShown" :type="isConfirmShown ? 'text' : 'password'"
      placeholder="placeholder.confirmPassword" inputLabel="label.confirmPassword" />
    <small v-if="v$.confirmPassword.$error" class="error pb-1">{{
      v$ && v$.confirmPassword.$errors[0].$message
    }}</small>
    <BaseButton class="primary w-md-auto mt-md-4" @click="resetPassword">{{ buttonLabel }}</BaseButton>
    <BaseDialog v-model="isDialogVisible" location="center" color="#2F313D" persistent class="pa-2 dialog-edit-profile">
      <v-card class="pa-8">
        <div class="dialog-text social">{{ dialogMessage }}</div>
      </v-card>
    </BaseDialog>
  </div>
</template>

<script>
import BaseInput from '@/components/base/BaseInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseDialog from '@/components/base/BaseDialog.vue';
import jsConfetti from '@/utils/ConfettiUtils';
import { required, sameAs, helpers, minLength, maxLength, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
export default {
  name: 'ResetPasswordForm',
  components: {
    BaseInput,
    BaseDialog,
    BaseButton,
  },
  props: {
    isChangePassword: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: '',
    },
    code: {
      type: String,
      default: '',
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      dialogMessage: '',
      isDialogVisible: false,
      isConfirmShown: false,
      isPasswordShown: false,
    };
  },
  validations() {
    return {
      password: {
        required: helpers.withMessage(this.$t('validation.passwordRequired'), required),
        minLength: helpers.withMessage(
          this.$t('validation.passwordMinLength'),
          minLength(8)
        ),
        maxLength: helpers.withMessage(
          this.$t('validation.passwordMaxLength'),
          maxLength(20)
        ),
        containsUppercase: helpers.withMessage(
          this.$t('validation.passwordContainsUppercase'),
          (value) => /[A-Z]/.test(value)
        ),
        containsLowercase: helpers.withMessage(
          this.$t('validation.passwordContainsLowercase'),
          (value) => /[a-z]/.test(value)
        ),
        containsNumber: helpers.withMessage(
          this.$t('validation.passwordContainsNumber'),
          (value) => /[0-9]/.test(value)
        ),
      },
      confirmPassword: {
        required: helpers.withMessage(
          this.$t('validation.confirmPasswordRequired'),
          required
        ),
        sameAsPassword: helpers.withMessage(
          this.$t('validation.confirmPasswordMustBeSame'),
          sameAs(this.password)
        ),
        $lazy: true,
      },
    }
  },
  computed: {
    buttonLabel() {
      return this.isChangePassword ? this.$t('button.changePassword') : this.$t('button.resetPassword');
    },
    inputLabel() {
      return this.isChangePassword ? 'label.newPassword' : 'label.password';
    },
    placeholder() {
      return this.isChangePassword ? 'placeholder.newPassword' : 'placeholder.password';
    },
  },
  methods: {
    showDialog(message) {
      this.dialogMessage = message;
      this.isDialogVisible = true;
    },
    clearDialog() {
      this.isDialogVisible = false;
      this.dialogMessage = '';
    },
    async resetPassword() {
      this.v$.$touch();
      if (this.v$.$error) {
        return;
      }

      const result = await this.$store.dispatch('UserStore/resetPassword', {
        code: this.code,
        email: this.email,
        password: this.password,
        isChangePassword: this.isChangePassword
      });

      if (result.isReset) {
        jsConfetti.addConfetti({
          emojis: ['🎉', '🎈', '🎊', '💥', '✨', '💫', '🌸'],
          confettiNumber: 100,
          emojiSize: 40,
        });
        this.$router.push({ name: 'login' });
      }

      if (result.isChanged) {
        this.showDialog(this.$t('dialog.passwordChanged'));
        this.password = '';
        this.confirmPassword = '';

        this.v$.$reset();

        setTimeout(() => {
          this.isDialogVisible = false;
        }, 2000);

      }
    },
  },
};
</script>

<style lang="scss" scoped></style>